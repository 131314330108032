<template>
  <div>
    <!-- Header Alert -->
    <b-alert
      show
      variant="light"
      class="alert alert-custom alert-white alert-shadow fade show gutter-b"
    >
      <div class="alert-icon">
        <span class="svg-icon svg-icon-lg">
          <inline-svg src="/media/svg/icons/Tools/Compass.svg" />
        </span>
      </div>
      <div class="alert-text">Daftar <b>Moderator</b></div>
      <b-button
        squared
        @click="$router.push('/moderate/add')"
        v-if="btnAccess"
        variant="primary"
        >Tambah</b-button
      >
    </b-alert>

    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <div>
              <!-- Filter -->
              <div class="row justify-content-end mt-n3">
                <!-- <div class="col-md-4">
                  <b-form-select
                    v-model="filter.role"
                    :options="roles"
                    @change="filterByRole"
                  >
                  </b-form-select>
                </div> -->

                <div class="col-md-4">
                  <form v-on:submit.prevent="onEnter">
                    <b-input-group>
                      <b-form-input
                        type="text"
                        v-model="filter.name"
                        placeholder="Cari Nama"
                      ></b-form-input>
                      <div class="input-group-append">
                        <button
                          type="button"
                          class="btn btn-primary"
                          @click="filterByName"
                        >
                          <i class="fas fa-search"></i>
                        </button>
                        <button
                          type="button"
                          class="btn btn-danger"
                          @click="resetFilter"
                        >
                          Reset
                        </button>
                      </div>
                    </b-input-group>
                  </form>
                </div>
              </div>

              <b-table
                hover
                :items="items"
                :fields="fields"
                class="mt-3"
                responsive
              >
                <template #table-colgroup="scope">
                  <col
                    v-for="field in scope.fields"
                    :key="field.key"
                    :style="{ width: field.key === 'action' ? '25%' : '' }"
                  />
                </template>

                <!-- <template #cell(lhp)="data">
                  <ul>
                    <li> <b>Nomor</b> : {{data.item.lhp_number}}</li>
                    <li> <b>Kelompok Audit</b> : {{data.item.lhp_audit_category_name}}</li>
                    <li> <b>Jenis Audit</b> : {{data.item.lhp_audit_type_name}}</li>
                  </ul>
                </template> -->

                <template #cell(photo_url)="data">
                  <div
                    class="
                      d-flex
                      justify-content-start
                      align-items-center
                      image-container
                    "
                  >
                    <div class="image-input-wrapper" style="position: relative">
                      <img class="image" :src="data.item.photo_url" />
                    </div>
                  </div>
                </template>

                <template #cell(action)="data">
                  <!-- <b-button
                    size="sm"
                    class="mr-1 btn-outline-info btn-circle"
                    v-b-tooltip.hover
                    title="Detail"
                    @click="
                      $router.push({
                        path: '/members/detail/' + data.item.id,
                      })
                    "
                    ><i class="fas fa-eye px-0"></i
                  ></b-button> -->
                  <b-button
                    size="sm"
                    class="mr-1 btn-outline-success btn-circle"
                    v-b-tooltip.hover
                    title="Edit"
                    @click="
                      $router.push({
                        path: '/moderate/edit/' + data.item.id,
                      })
                    "
                    v-if="data.item.status != 'special' && btnAccess"
                    ><i class="fas fa-edit px-0"></i
                  ></b-button>
                  <b-button
                    size="sm"
                    class="mr-1 btn-outline-danger btn-circle"
                    v-b-tooltip.hover
                    title="Hapus"
                    @click="deleteData(data.item.id)"
                    v-if="data.item.status != 'special' && btnAccess"
                    ><i class="fas fa-trash px-0"></i
                  ></b-button>
                  <b-button
                    size="sm"
                    class="btn-outline-info btn-circle"
                    v-b-tooltip.hover
                    title="Info"
                    @click="data.toggleDetails"
                    v-if="btnAccess"
                    ><i class="fas fa-info px-0"></i
                  ></b-button>
                </template>

                <template #row-details="data">
                  <div class="row justify-content-center">
                    <div class="col-12">
                      <div class="card card-custom">
                        <div class="card-body p-0">
                          <!--begin: Wizard-->
                          <div
                            class="wizard wizard-1"
                            id="kt_wizard_v1"
                            data-wizard-state="step-first"
                            data-wizard-clickable="true"
                          >
                            <!--begin: Wizard Body-->
                            <div
                              class="
                                row
                                justify-content-center
                                my-4
                                px-4
                                my-lg-5
                                px-lg-5
                              "
                            >
                              <div class="col-xl-12">
                                <!--begin: Wizard Form-->

                                <!--begin: Wizard Step 1-->
                                <div
                                  class="pb-5"
                                  data-wizard-type="step-content"
                                  data-wizard-state="current"
                                >
                                  <h5
                                    class="
                                      font-weight-bolder
                                      text-primary
                                      font-size-h4 font-size-h4-lg
                                    "
                                  >
                                    Detail Moderator
                                  </h5>
                                  <table class="table mt-8 table-detail">
                                    <tr></tr>
                                    <tr>
                                      <!--3  -->
                                      <td
                                        colspan="3"
                                        rowspan="3"
                                        class="border-0"
                                      >
                                        <div
                                          class="d-flex justify-content-center"
                                        >
                                          <img
                                            :src="data.item.photo_url"
                                            alt=""
                                            style="max-width: 150px"
                                          />
                                        </div>
                                      </td>

                                      <th
                                        class="pr-1"
                                        width="100"
                                        style="white-space: nowrap"
                                      >
                                        <span>Nama </span>
                                      </th>
                                      <th class="pl-0 pr-0" width="10">
                                        <span>:</span>
                                      </th>
                                      <td class="pl-2">
                                        <span>{{ data.item.name }}</span>
                                      </td>
                                    </tr>
                                    <tr>
                                      <th class="pr-1">
                                        <span>Email</span>
                                      </th>
                                      <th class="pl-0 pr-0">
                                        <span>:</span>
                                      </th>
                                      <td class="pl-2">
                                        <span>{{ data.item.email }}</span>
                                      </td>
                                    </tr>
                                    <tr>
                                      <th class="pr-1">
                                        <span>Telepon</span>
                                      </th>
                                      <th class="pl-0 pr-0">
                                        <span>:</span>
                                      </th>
                                      <td class="pl-2">
                                        <span>{{ data.item.phone }}</span>
                                      </td>
                                    </tr>

                                    <!-- <tr>
                                      <th colspan="3" class="border-0">
                                        <span class="text-primary h4"
                                          ><b>Detail Usaha</b></span
                                        >
                                      </th>
                                    </tr>
                                    <tr>
                                      <th class="pr-1">
                                        <span>Nama UMKM</span>
                                      </th>
                                      <th class="pl-0 pr-0">
                                        <span>:</span>
                                      </th>
                                      <td class="pl-2">
                                        <span>{{
                                          data.item.business_name
                                        }}</span>
                                      </td>

                                      <th class="pr-1">
                                        <span>Tentang UMKM</span>
                                      </th>
                                      <th class="pl-0 pr-0">
                                        <span>:</span>
                                      </th>
                                      <td class="pl-2">
                                        <span>{{ data.item.description }}</span>
                                      </td>

                                      <th class="pr-1">
                                        <span>Alamat UMKM</span>
                                      </th>
                                      <th class="pl-0 pr-0">
                                        <span>:</span>
                                      </th>
                                      <td class="pl-2">
                                        <span>{{
                                          data.item.business_address
                                        }}</span>
                                      </td>
                                    </tr>

                                    <tr>
                                      <th class="pr-1">
                                        <span>Peran UMKM</span>
                                      </th>
                                      <th class="pl-0 pr-0">
                                        <span>:</span>
                                      </th>
                                      <td class="pl-2">
                                        <span>{{
                                          data.item.member_role_name
                                        }}</span>
                                      </td>
                                      <th class="pr-1">
                                        <span>Industri UMKM</span>
                                      </th>
                                      <th class="pl-0 pr-0">
                                        <span>:</span>
                                      </th>
                                      <td class="pl-2" colspan="4">
                                        <span>{{
                                          data.item.industry_type_name
                                        }}</span>
                                      </td> -->
                                      <!-- <th class="pr-1">
                                        <span>Kota Usaha</span>
                                      </th>
                                      <th class="pl-0 pr-0">
                                        <span>:</span>
                                      </th>
                                      <td class="pl-2">
                                        <span>{{
                                          data.item.business_city
                                        }}</span>
                                      </td> -->

                                      <!-- <th class="pr-1">
                                        <span>Lama Usaha</span>
                                      </th>
                                      <th class="pl-0 pr-0">
                                        <span>:</span>
                                      </th>
                                      <td class="pl-2" colspan="4">
                                        <span
                                          >{{
                                            data.item.business_estabilished_age
                                          }}
                                          Tahun</span
                                        >
                                      </td> -->
                                    <!-- </tr> -->

                                    <!-- <tr>
                                      <th class="pr-1">
                                        <span>Tipe Usaha</span>
                                      </th>
                                      <th class="pl-0 pr-0">
                                        <span>:</span>
                                      </th>
                                      <td class="pl-2" colspan="4">
                                        <span>{{
                                          data.item.industry_type_name
                                        }}</span>
                                      </td>
                                    </tr> -->
                                  </table>
                                  <!-- <div class="row mx-3">
                                    <span class="text-primary h4"
                                      ><b>Produk Unggulan</b></span
                                    >
                                  </div>
                                  <div class="row mx-3">
                                    <table class="table">
                                      <tr>
                                        <th>No</th>
                                        <th>Gambar</th>
                                        <th>Nama</th>
                                        <th>Harga</th>
                                      </tr>
                                    <tr
                                      v-for="(item, index) in data.item
                                        .member_products"
                                      :key="index"
                                    >
                                      <td>{{ index + 1 }}</td>
                                      <td>
                                        <div
                                          class="
                                            d-flex
                                            justify-content-start
                                            align-items-center
                                            image-container
                                          "
                                        >
                                          <div
                                            class="image-input-wrapper-product"
                                            style="position: relative"
                                          >
                                            <img
                                              class="image"
                                              :src="item.image"
                                            />
                                          </div>
                                        </div>
                                      </td>
                                      <td>{{ item.name }}</td>
                                      <td>
                                        {{
                                          item.price.toLocaleString("id-ID")
                                        }}
                                      </td>
                                    </tr>
                                    </table>
                                  </div> -->
                                </div>
                                <!--end: Wizard Step 1-->
                              </div>
                            </div>
                            <!--end: Wizard Body-->
                          </div>
                        </div>
                        <!--end: Wizard-->
                      </div>
                    </div>
                  </div>
                </template>
              </b-table>

              <b-pagination
                v-if="items.length != 0"
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                @page-click="pageOnClick"
                class="mt-4"
              ></b-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import module from "@/core/modules/CrudModule.js";

export default {
  data() {
    return {
      // filter
      filter: {
        name: "",
        role: 0,
      },
      roles: [],
      // Pagination
      perPage: 20,
      currentPage: 1,
      totalRows: 1,
      fields: [
        {
          key: "number",
          label: "No",
          sortable: true,
        },
        {
          key: "photo_url",
          label: "Foto",
          sortable: true,
        },
        {
          key: "name",
          label: "Nama",
          sortable: true,
          tdClass: "set-max-width",
        },
        {
          key: "email",
          label: "Email",
          sortable: true,
          tdClass: "set-max-width",
        },
        {
          key: "member_role_name",
          label: "Peran",
          sortable: true,
        },
        // {
        //   key: "business_name",
        //   label: "Usaha",
        //   sortable: true,
        // },
        // {
        //   key: "industry_type_name",
        //   label: "Industri",
        //   sortable: true,
        // },
        {
          key: "action",
          label: "",
          tdClass: "nowrap",
        },
      ],
      items: [],
      // access
      btnAccess: true,
    };
  },
  methods: {
    pageOnClick(evt, page) {
      evt.preventDefault();
      //("evt", evt);
      //("page", page);
      this.currentPage = page;
      this.pagination();
    },

    async pagination() {
      let filterParams = `&name=${this.filter.name}&role=9`;
      let response = await module.paginate(
        "api/members",
        `?page=${this.currentPage}&page_size=${this.perPage}${filterParams}`
      );
      let pagination = response.meta.pagination;
      this.totalRows = pagination.total;

      let a, b;
      b = this.perPage * (this.currentPage - 1) + 1;
      for (a = 0; a < response.data.length; a++) {
        response.data[a].number = b + a;
      }

      this.items = response.data;
    },

    filterByRole(roles) {
      this.filter.role = roles;
      this.pagination();
    },

    filterByName() {
      this.pagination();
    },

    onEnter(e){
      e.preventDefault()

      this.pagination()
    },

    resetFilter(){
      this.filter.name = ''
      this.filter.role = 0
      this.pagination()
    },

    async deleteData(id) {
      // Delete Data
      let result = await module.delete("api/members/" + id);
      // If Deleted
      if (result) {
        this.pagination();
      }
    },

    async statusOptions() {
      let response = await module.setSelectOption("api/member-roles");
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.roles = response.data;
        this.roles.unshift({
          text: "Semua Peran",
          value: 0,
        });
      }
    },

    // access
    checkAccess() {
      let access_right_user = window.localStorage.getItem(
        "access_right_display"
      );
      let access_right = JSON.parse(access_right_user);
      for (let a = 0; a < access_right.length; a++) {
        if (access_right[a] == "6006") {
          this.btnAccess = true;
        }
      }
    },
},
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Anggota", route: "/members" },
    ]);
    this.pagination();
    this.checkAccess();
    this.statusOptions();
  },
};
</script>

<style>
.nowrap {
  white-space: nowrap;
}
.btn-circle {
  width: 40px;
  height: 40px;
  border-radius: 35px;
}
.image {
  max-width: 50px;
}
.table-detail.td {
  padding: 2px !important;
}

.image-input-wrapper-product {
  width: 50px !important;
  height: 50px !important;
}

.image-input-wrapper-product img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.set-max-width{
  max-width: 14rem;
}
</style>
